import React from "react"
import { Link } from "gatsby"
import link from "../images/link.svg"
import emergency from "../images/emergency.svg"
import family from "../images/family.svg"
import teeth from "../images/teeth.svg"
import veneer from "../images/veneer.svg"
import implant from "../images/implants.svg"

const ServicesComponent = () => {
  return (
    <>
      <div className="section">
        <div className="sub-container"></div>
        <div className="container">
          <div className="flex space-between wrap">
            <div className="service-box">
              <Link to="/services/emergency-dentist">
                <div>
                  <img src={emergency} alt="hospital" />
                </div>

                <div>
                  <p className="bold-p">Emergency Dentistry</p>
                  <p className="white">
                    Don’t wait until the condition gets worse — see us
                    immediately.
                  </p>
                </div>
                <div className="right pointer">
                  <img src={link} alt="link" />
                </div>
              </Link>
            </div>
            <div className="service-box">
              <Link to="/services/family-dentist">
                <div>
                  <img src={family} alt="family icon" />
                </div>
                <div>
                  <p className="bold-p">Family Dentistry</p>
                  <p className="white">
                    Best service for you and your family to have regular dental
                    check ups.
                  </p>
                </div>
                <div className="right pointer">
                  <img src={link} alt="link" />
                </div>
              </Link>
            </div>
            <div className="service-box">
              <Link to="/services/cosmetic-dentist">
                <div>
                  <img src={teeth} alt="hospital" />
                </div>
                <div>
                  <p className="bold-p">Cosmetic Dentistry</p>
                  <p className="white">
                    This service aims to improve your appearance, with or
                    without accidents
                  </p>
                </div>
                <div className="right pointer">
                  <img src={link} alt="link" />
                </div>
              </Link>
            </div>
            <div className="service-box">
              <Link to="/services/veneers">
                <div>
                  <img src={veneer} alt="hospital" />
                </div>
                <div>
                  <p className="bold-p">Veneers</p>
                  <p className="white">
                    If you want to have that perfect smile but are afraid of
                    surgery.
                  </p>
                </div>
                <div className="right pointer">
                  <img src={link} alt="link" />
                </div>
              </Link>
            </div>
            <div className="service-box">
              <Link to="/services/dental-implants">
                <div>
                  <img src={implant} alt="hospital" />
                </div>
                <div>
                  <p className="bold-p">Dental Implants</p>
                  <p className="white">
                    Dental implants can help improve the general alignment of
                    your teeth.
                  </p>
                </div>
                <div className="right pointer">
                  <img src={link} alt="link" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesComponent
